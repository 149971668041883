import './Home.css';
import Carousel from 'react-bootstrap/Carousel';
import { useState, useEffect } from 'react';
import movieScreen from './movieScreen.png'
import money1 from './/money1.jpg'
import money2 from './/money2.jpg'
import stockMarket from './/stockMarket.jpg'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Ring } from '@uiball/loaders';
import video1 from './videos/video1.mp4';
import video2 from './videos/video2.mp4';
import video3 from './videos/video3.mp4'
const Home = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productImg, setProductImg] = useState('');
  const [productID, setProductID] = useState('')
  const [loading, setLoading] = useState(false)
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getProducts = async () => {
    try {
      let response = await fetch('https://financebackend.darien-c.com/products/', {
        method: 'GET'
      });
  
      let data = await response.json();
  
      if (data) {
        const featuredProductsArray = data.filter((product) => product.featured === true);
        setFeaturedProducts(featuredProductsArray);
      } else {
        setLoading(true);
        setTimeout(() => {
          getProducts(); // Corrected: pass a function to setTimeout
        }, 2500);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      // Handle the error here if needed
      setLoading(true); // Set loading to true in case of an error
    }
  };
  
  useEffect(() => {
    getProducts();
  }, []);
  

   //Add Item To Cart Logic
  

   const addToCart = async (productId) => {
    try {
      const cart_id = localStorage.getItem('cart_id')

  
      const response = await fetch(`https://financebackend.darien-c.com/cart/add/${productId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // include the withCredentials option
        body: JSON.stringify({ cart_id }),
        
      });
      let data = await response.json()
  
      if (response.ok) {
        // Handle successful add-to-cart response
        let cart_id = data['id']
        localStorage.setItem('cart_id',cart_id)
        toast.success('Product added to cart successfully');
      } else {
        // Handle error response
      }
    } catch (error) {
    }
  };
  const ProductShow= async(productID)=>{
    const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/)?.[1];
    const product_Id = productID
    const response = await fetch(`https://financebackend.darien-c.com/productInfo/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Csrftoken': csrfToken,
        },
        credentials: 'include', // include the withCredentials option
        body: JSON.stringify({ product_Id }),
        
        
      });
      let data = await response.json()
      if (response.ok){
        
        setProductName(data['name'])
        
        setProductDescription(data['description'])
        setProductPrice(data['price'])
        setProductImg(data['image'])
        setProductID(data['id'])
        setShowModal(true)
      }
      else{
      }
  }

  




  return (
    <div>
    
        <div>
     

        <div className="carousel-container">
      <Carousel  >
      <Carousel.Item interval={7000}>
      <div className="videoContainer">
      <video src={video1} alt="video1" className="videoC" autoPlay loop muted playsInline/>
        <Carousel.Caption className="middle-caption">
          <div className="CCaption">
          <h3>FFS Finance</h3>
          </div>
        </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={7000}>
      <div className="videoContainer">
      <video src={video2} alt="video2" className="videoC" autoPlay loop muted playsInline/>
        <Carousel.Caption className="middle-caption">
          <div className="CCaption">
          <h3>FFS Finance</h3>
          </div>
        </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={7000}>
        <div className="videoContainer">
      <video src={video3} alt="video3" className="videoC" autoPlay loop muted playsInline/>
        <Carousel.Caption className="middle-caption">
        <div className="CCaption">
          <h3>FFS Finance</h3>
          </div>

        </Carousel.Caption>
        </div>
      </Carousel.Item>
    </Carousel>
    
    </div>

    <main className="productList">
  <h2>Featured Products</h2>
  <ul>
    {featuredProducts.map((product) => (
      <li key={product.id} className="product-item">
        <div className="product-card">
          <div className="product-image">
            <img src={`https://financebackend.darien-c.com/static${product.image}`} alt={product.name} onClick={() => ProductShow(product.id)} className='productImg'/>          
          </div>
          
          <div className="product-details">
            <h3 className="product-name">{product.name} - <small className="product-price">${product.price}</small></h3>
            
            
            <button className="add-to-cart-button" onClick={() => addToCart(product.id)}>Add To Cart</button>
          </div>
        </div>
      </li>
    ))}
  </ul>
  <div className='modal-wrapper'>
  <Modal show={showModal} onHide={handleCloseModal} className='productModal'>
      <Modal.Header>
        <Modal.Title style={{width:'100%'}}>{productName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{productDescription}</p>
        <p>Price: {productPrice}</p>
        <img src={`https://financebackend.darien-c.com/static${productImg}`} alt={productName} className='modalImg'/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={() => addToCart(productID)} >
          Add to Cart
        </Button>
      </Modal.Footer>
    </Modal>
    </div>

</main>
</div>

    </div>
  );
}

export default Home;

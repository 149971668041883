import './App.css';
import Header from './pages/Header';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Blog from './pages/Blog';
import Products from './pages/Products';
import Footer from './components/Footer';
import ViewCart from './pages/ViewCart';
import CheckOut from './pages/CheckOut';
import AccountInfo from './pages/AccountInfo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import BlogPost from './pages/BlogPost';
import ShippingForm from './pages/ShippingForm';
import ConfirmationPage from './components/ConfirmationPage';
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

function App() {
  const stripePromise = loadStripe('pk_test_51NEuH0Ga2RrHJG1q5vZbSggUSkJjGZXbRQurWVtjxiKy3qVm8FvYEOINNUhcScq3LuLeoj7OMIoBmAnkY7CmyYWQ00bbCmRmbG');

  
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <ToastContainer />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/viewcart" element={<ViewCart />} />
          <Route exact path="/accountInfo" element={<AccountInfo />} />
          <Route exact path="/blogPost/:id" element={<BlogPost/>}/>
          <Route exact path='/confirmationPage' element={<ConfirmationPage/>}/>
         
          <Route
            exact
            path="/shippingAddress"
            element={
              <Elements stripe={stripePromise}>
                
                <ShippingForm />
                
              </Elements>
            }
          />

        </Routes>
     
       
        
      </div>
      <Footer/>
    </BrowserRouter>
   
  );
}

export default App;
// This is a sample asynchronous function
import { useNavigate } from "react-router-dom";
async function SubmitOrder(shippingInfo, billingInfo, shippingAddress, billingAddress, cartItems, navigate) {
    const url = 'https://financebackend.darien-c.com/order/';
    const productsInfo = cartItems.map(item => ({
        product: item.product,
        quantity: item.quantity
    }));
    const productsInfoString = JSON.stringify(productsInfo);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            
                "email":shippingInfo.email,
                "main_phone_number": shippingInfo.phone,
                "shipping_first_name": shippingInfo.firstName,
                "shipping_last_name": shippingInfo.lastName,
                "shipping_full_address": shippingAddress,
                "billing_first_name": billingInfo.billingFirstName,
                "billing_last_name" : billingInfo.billingLastName,
                "billing_full_address": billingAddress,
                "products_info" : productsInfoString
            
        }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const orderInfo = data
      localStorage.removeItem('cart_id')

      navigate("/confirmationPage", { state: { order:orderInfo } });

      return data;  // You can return the response data if needed
    } catch (error) {
      console.error('Error submitting order:', error);
      throw error;  // Re-throw the error to handle it at a higher level if needed
    }
  }
  
  export { SubmitOrder };
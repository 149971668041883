import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import account from './account.png';
import logoMain from './logo-resized-tiny.png';
import menuIcon from './icons8-menu-100.png'
import cart from './cart.svg';
import './Header.css';
import './Header.css';
import { Modal, Button, Form } from "react-bootstrap";
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import RegisterModal from '../components/RegisterModal';
import SubmitRegistration from '../services/SubmitRegistration';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const popOutRef = useRef(null);
  const location = useLocation();
  const [loginModal, setLoginModal] = useState(false)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cartItems, setCartItems] = useState([])
  const modalRef = useRef(null);
  const cartTooltipRef = useRef(null);
  const [user, setUser] = useState('');
  const [userModal, setUserModal] = useState(false)
  const [toggleModal, setToggleModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
//SideBar JS
  const handleMenuClick = () =>{
    setIsOpen(!isOpen)
  }
  const handleClickOutside = (event) => {
    if (popOutRef.current && !popOutRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
//Login Modal JS
  const handleModalClick = () =>{
    if (toggleModal){
      setUserModal(true)
    }
    else{
      setLoginModal(!loginModal)
    }

    

  }
  const handleClickOutsideModal = (event) => {
    const modal = document.querySelector('.modal-content'); // replace '.modal' with your actual modal selector
    if (modal && !modal.contains(event.target)) {
      setLoginModal(false);
      setUserModal(false);
    }
  };
  //Login Functionality 
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email, password)
    const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/);


    const response = await fetch("https://financebackend.darien-c.com/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken

      },
      body: JSON.stringify({
        "username": email,
        "password": password,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      // login successful
      // store the token in local storage or session storage
      setLoginModal(false)
      toast.success(data.user.username + " Logged In Successfully!")
      localStorage.setItem("authToken", data.token);
      setTimeout(()=>{
        window.location.reload()
      }, 1500)


    } else {
      // login failed
      toast.error("Login Unsuccessful Please Try Again");
    }
  };
  //View Cart
  const getCart = async () => {
    const cart_id = localStorage.getItem('cart_id')
    try {
      
      const response = await fetch('https://financebackend.darien-c.com/cart/', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken
  
        },
        credentials:'include',
        body: JSON.stringify({ 'cart_id':cart_id }),

      });
      const data = await response.json();
      if (response.ok) {
        setCartItems(data.items);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideModal);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);
  const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/);
  const googleLogin = async (response) => {
    const idToken = response.credential;
    const url = 'https://financebackend.darien-c.com/googleLogin/'

    try{
      const backendResponse = await fetch(url,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json'
          
        },
        credentials: "include",
        body: JSON.stringify({idToken})
      });
      const data = await backendResponse.json();
      let token = data['token']
      if (backendResponse.ok) {
        // login successful
        // store the token in local storage or session storage
        
        localStorage.setItem("authToken", token)
        window.location.reload();


        toast.success('Welcome');
  

        // redirect to dashboard or profile page
        
      } else {
        // login failed
      }
    
    } catch (error) {
      console.error('Error:', error);
    }}
  const errorMessage = ()=>{
  }



  useEffect(() => {
    const authCheck = () =>{
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        // Fetch user information using the authToken
        // Replace the following code with your own implementation
        const getUserInformation = async () => {
          try {
            // Make an API request to fetch user information
            const response = await fetch('https://financebackend.darien-c.com/userInfo/', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
              },
            });
            if (response.ok) {
              const data = await response.json();
              setUser(data['username'])
              setToggleModal(true)
   
              // setUserModal(true);
            } else {
              // Handle error if user information retrieval fails
              throw new Error('Failed to fetch user information');
            }
          } catch (error) {
          }
        };
        getUserInformation()
      }}


      authCheck();
    
  }, []);
  const handleLogout = async()=>{
    const authToken = localStorage.getItem('authToken');
   
      try {
        // Make an API request to fetch user information
        const response = await fetch('https://financebackend.darien-c.com/logout/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${authToken}`,
          },
        });
        if (response.ok) {
          setUser(null);
          setToggleModal(false);
          localStorage.removeItem('authToken');
          window.location.reload();
        
          toast.info('Goodbye');
        } else {
          // Handle error if user information retrieval fails
          throw new Error('Failed to fetch user information');
        }
      } catch (error) {
      }
    };
    const handleOpenRegister = () =>{
      setLoginModal(false)
      setShowRegisterModal(true)
      
      
    }
    const [registerData, setRegisterData] = useState({
      email:"",
      username:"",
      password1:"",
      password2:""
  })
  const [submitRegisterData, setSubmitRegisterData] = useState({
    email: "",
    username:"",
    password:""
  })
  const handleRegisterData = e => {
      setRegisterData({
          ...registerData,
          [e.target.name]: e.target.value
      });
  };
  const handleSubmitRegistration = () =>{
    console.log("thing is firing")
    if(registerData.password1 != registerData.password2){
      toast.error("Passwords do not match!")
  }
  else{
    SubmitRegistration({ registerData })
    .then((data) => {
        setShowRegisterModal(false)
        toast.success(data.user.username + " Registered Successfully")
        localStorage.setItem("authToken", data.token);
        setTimeout(()=>{
          window.location.reload()
        }, 1500)
    })
    .catch((error) => {
        console.error('Error:', error);
    });

  }}
  

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    document.body.style.touchAction = isOpen ? 'none' : 'auto'

    // Cleanup function to restore default scrolling on unmount
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  


  return (
  <div>
    
    <div className="navbar">
        <div className='menuIcon'>
    
      <img src={menuIcon} alt='Logo' className='menuImg'onClick={handleMenuClick} style={{cursor:'pointer'}}/>
    
  </div>
      <div className="navLogo" id={location.pathname==='/'? 'homeLogo' : ''}><a href='/'>
    <img src={logoMain} alt="Logo" className='logoImg' />
  </a></div>
  <div className="cartContainer">
  <div className="cartIcon" onMouseEnter={getCart}>
    <img src={cart} alt='cartIcon' className='cartImg'/>
  </div>
  <div className="cartTooltip" ref={cartTooltipRef}>
  <h4>Your Cart</h4>
  <ul>

    {cartItems.map((item,index) => (
      <div className="cartIndividualItem">
      <li key={index}>
        <div><img src={`https://financebackend.darien-c.com/static${item.product.image}`} alt={item.product.name} className='cartProductImg'></img>
          <br/>
          {item.product.name}
        <br/>
        <b>$</b>{item.product.price}
        <br/>
        Qty:{item.quantity}
        </div>

      </li>
      </div>
      
    ))}
    
  </ul>
  <a href='/viewcart'>
  <button>View Cart</button>
</a>
</div>
</div>
  <div className='accountIcon' onClick={handleModalClick}>
 
    <img src={account} alt='login' className='accountImg'/>
   

  </div>

  <div className='popOut'>



  </div>
    </div>
      <div className='popOut' style={{ left: isOpen ? '0' : '-100%', zIndex: isOpen ? '2' : '0' }} ref={popOutRef}>
    <ul>
        <a href='/'><li className="navbar-menu__item">Home</li></a>
        <a href='/blog'><li className="navbar-menu__item">Blog</li></a>
        <a href='/products'><li className="navbar-menu__item">Products</li></a>
        <a href='/about'> <li className="navbar-menu__item">About</li></a>
        <a href='/contact'><li className="navbar-menu__item">Contact</li></a>
      </ul>
      </div>
     
        <div
          className="overlay"
          style={{ opacity: isOpen ? '1' : '0', zIndex:isOpen ? 1: '-1'}}
        ></div>
    <div>
    <div ref={modalRef}>

    <Modal show={loginModal} className='modal'>

      <Modal.Body>

        <Modal.Title style={{padding:'5px'}}>Login</Modal.Title>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="username"
              name="email"
              placeholder="Enter Username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}

            />
          </Form.Group>
          

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}

            />
          </Form.Group>
          <Button variant="primary" type="submit">
          Login
        </Button>
        </Form>
        <br/>
        <GoogleLogin onSuccess={googleLogin} onError={errorMessage} />
      </Modal.Body>
      <Modal.Title>Sign Up</Modal.Title>
      <Button variant="secondary" onClick={handleOpenRegister}>
        Register Here        </Button>
       {/* <img src={google} alt='googleIcon'style={{width: '30px', height: '30px'}}/> */}
      <Modal.Footer>


      </Modal.Footer>
    </Modal>
    <Modal show={userModal} className='modal'>
      <Modal.Body>
        <h3>Welcome: {user}</h3>
        <br/>
        <a href='/accountInfo'>
          <Button variant='primary' style={{width:'100%'}}>Account Info</Button>
        </a>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
       <Button variant='danger' onClick={handleLogout}>Logout</Button>
    </Modal>
    <Modal show={showRegisterModal} onHide={() => setShowRegisterModal(false)}>
                <Modal.Header>
                <Modal.Title >
                    Register Account
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 <Form>
                    <Form.Group className="mb-3" controlId="formBasicRegEmail">
                        <Form.Label>Enter Email Address</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter Email" onChange={handleRegisterData} value={registerData.email}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicRegEmail">
                        <Form.Label>Enter Username</Form.Label>
                        <Form.Control type="username" name="username" placeholder="Enter Username" onChange={handleRegisterData} value={registerData.username}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicRegPassword">
                        <Form.Label>Enter Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Password" name="password1"onChange={handleRegisterData} value={registerData.password1}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" name="password2"onChange={handleRegisterData} value={registerData.password2}/>
                    </Form.Group>
                    <Button variant="outline-dark" onClick={(()=>handleSubmitRegistration())}>Submit Registration</Button>


                 </Form>
                </Modal.Body>

            </Modal>
    
    </div>
    </div>

    
    
    
    </div>
    
  );
}

export default Header
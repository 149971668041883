import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { configureStore } from "@reduxjs/toolkit";
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <GoogleOAuthProvider clientId="497761915810-oivlb290s9cdhk6tgssjot0768liit05.apps.googleusercontent.com">

  <React.StrictMode>
    <App />

  </React.StrictMode>
  </GoogleOAuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from 'react';
import './AccountInfo.css';

const AccountInfo = () => {
  const [email, setEmail] = useState('')
  
  useEffect(() => {
    const authCheck = () =>{
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        // Fetch user information using the authToken
        // Replace the following code with your own implementation
        const getUserInformation = async () => {
          try {
            // Make an API request to fetch user information
            const response = await fetch('https://financebackend.darien-c.com/userInfo/', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
              },
            });
            if (response.ok) {
              const data = await response.json();
              setEmail(data['username'])
              
   
              // setUserModal(true);
            } else {
              // Handle error if user information retrieval fails
              throw new Error('Failed to fetch user information');
            }
          } catch (error) {
          }
        };
        getUserInformation()
      }}
    authCheck();
  }, []); // The empty dependency array ensures the effect runs only once on initial mount

  const orderHistory = [
    {
      orderNumber: '#1',
      date: 'June 1, 2023',
      product: 'Product A',
      total: '$99.99',
    },
    {
      orderNumber: '#2',
      date: 'May 15, 2023',
      product: 'Product B',
      total: '$49.99',
    },
  ];

  const [activeTab, setActiveTab] = useState('account');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="account-page">
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'account' ? 'active' : ''}`}
          onClick={() => handleTabClick('account')}
        >
          Account Information
        </button>
        <button
          className={`tab ${activeTab === 'orders' ? 'active' : ''}`}
          onClick={() => handleTabClick('orders')}
        >
          Order History
        </button>
        <button
          className={`tab ${activeTab === 'privacy' ? 'active' : ''}`}
          onClick={() => handleTabClick('privacy')}
        >
          Privacy Policy
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'account' && (
          <div>
            <h2>Account Information</h2>
            <p>Email: {email}</p>
            </div>
            )}
           


        {activeTab === 'orders' && (
          <div>
            <h2>Order History</h2>
            {orderHistory.map((order) => (
              <div key={order.orderNumber}>
                <p>Order #{order.orderNumber}</p>
                <p>Date: {order.date}</p>
                <p>Product: {order.product}</p>
                <p>Order Total: {order.total}</p>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'privacy' && (
          <div>
            <h1>Privacy Policy</h1>
            <p>Last Updated: June 7th, 2023</p>

            <h2>1. Information We Collect</h2>
            <h3>1.1 Personal Information:</h3>
            <p>
                We may collect personal information from you when you voluntarily provide it to us during the account creation process, order placement, or when you contact us. This information may include your name, email address, shipping address, billing address, phone number, and payment details.
            </p>

            <h3>1.2 Log Data:</h3>
            <p>
                When you visit our website, our servers automatically collect certain information, known as log data. This may include your IP address, browser type, referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information to analyze trends, administer the site, track users' movements around the site, and gather demographic information.
            </p>

            <h2>2. Use of Information</h2>
            <h3>2.1 Personal Information:</h3>
            <p>
                We may use the personal information we collect to process and fulfill your orders, communicate with you about your orders and account, respond to your inquiries, improve our products and services, and customize your shopping experience. With your consent, we may also send you promotional materials and updates about our products and special offers.
            </p>

            <h3>2.2 Log Data:</h3>
            <p>
                We use log data to analyze trends, administer the website, track user movements, and gather demographic information. This information helps us to improve our website and tailor it to the needs and preferences of our users.
            </p>
            <h2>3. Data Security</h2>
            <p>We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
            <h2>4. Sharing of Information</h2>
            <p>We do not sell, trade, or rent your personal information to third parties for their marketing purposes. However, we may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you. These service providers are contractually obligated to keep your information confidential and secure.</p>
            <h2>5. Cookies</h2>
            <p>We use cookies and similar technologies to enhance your browsing experience, personalize content, and analyze website traffic. You can choose to accept or decline cookies through your browser settings. Please note that disabling cookies may limit your access to certain features and functionality of our website.</p>
            <h2>6. Changes to this Privacy Policy</h2>
            <p>We reserve the right to update or modify this Privacy Policy at any time. We will post the revised policy on our website with the updated date. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.</p>
            <h2>7. Contact Us</h2>
            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at finance.ffs@gmail.com.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountInfo;

import React, { useEffect, useState  } from 'react';
import './ShippingAddressForm.css';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import {
  GeoapifyGeocoderAutocomplete,
  GeoapifyContext
} from '@geoapify/react-geocoder-autocomplete';
import { useNavigate } from 'react-router-dom';
import TaxRate from '../services/TaxRate';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCheckbox,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import TaxRateCalculator from '../services/TaxRateCalculator';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import AutocompleteInput from './AutoComplete';
import { SubmitOrder } from '../services/SubmitAddress';
import CheckoutForm from './CheckoutForm';
import { Modal,Button } from 'react-bootstrap';
const AddressForm = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [billingCollapse, setBillingCollapse] = useState(false);
  const [shippingCollapse, setShippingCollapse] = useState(true);
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('')
  const [taxRate, setTaxRate] = useState(0)
  const toggleBillingCollapse = () => setBillingCollapse(!billingCollapse);
  const toggleShippingCollapse = () => {setShippingCollapse(!shippingCollapse);}
  const apiKey = '533b158ad66d44bfaa51c58677d424ea'; // Replace with your actual API key
  const [shippingValue, setShippingValue] = useState('');
  const [selectedShippingPlace, setShippingSelectedPlace] = useState(null);
  const [billingValue, setBillingValue] = useState('')
  const [selectedBillingPlace, setBillingSelectedPlace] = useState(null);
  const [cartItems, setCartItems] = useState([])
  const [cartTotal, setCartTotal] = useState(0)
  const [finalTotalAmount, setFinalTotalAmount] = useState(0)
  const navigate = useNavigate()

  
  const handleShippingPlaceSelect = (place) => {
    setShippingSelectedPlace(place);

  };
  const handleBillingPlaceSelect = (place) => {
    setBillingSelectedPlace(place);
  };
  const [shippingInfo, setShippingInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phone: '',
    additionalInfo: '',
    isAccountCreated: true,  // Assuming default value is true based on your example
  });
  const [billingInfo, setBillingInfo] = useState({
    billingAddress: '',
    billingFirstName: '',
    billingLastName: ''
  })
  const handleInputChange = (field, value) => {
    setShippingInfo(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  const handleBillingInputChange = (field, value)=>{
    setBillingInfo(prevState=>({
      ...prevState,
      [field]:value
    }))

    
  }
  const handleSubmit = async () => {
    try {
      const addresses = document.querySelectorAll('.geoapify-autocomplete-input');
      const shippingAddress = addresses[0].value;
      const billingAddress = addresses[1].value;
      const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/);
  
      // Get cart items
      const cart_id = localStorage.getItem('cart_id');
      const response = await fetch('https://financebackend.darien-c.com/cart/', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken
        },
        credentials: 'include',
        body: JSON.stringify({ cart_id }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }
  
      const data = await response.json();
      setCartItems(data.items);
  
      // Submit order
      await SubmitOrder(shippingInfo, billingInfo, shippingAddress, billingAddress, data.items, navigate);
  
      // If SubmitOrder is successful, you can perform additional actions here
    } catch (error) {
      // Handle any errors that occur during order submission
    }
  };
  const handleShow = (e) => {
    e.preventDefault()
    
    // Check if all required fields are filled in
    if (
      shippingInfo.firstName &&
      shippingInfo.lastName &&
      shippingInfo.email &&
      shippingInfo.phone &&
      billingInfo.billingFirstName &&
      billingInfo.billingLastName
      // Add more conditions for other required fields if needed
    ) {
      setShowPaymentModal(true);
    } else {
      toast.error('Please fill in all required fields.');
    }
  
  }
  const handleCloseModal = () => {
    setShowPaymentModal(false);
  };  
  useEffect(()=>{
    const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/);
    const getCart = async () => {
      const cart_id = localStorage.getItem('cart_id')
      try {
        
        const response = await fetch('https://financebackend.darien-c.com/cart/', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken
    
          },
          credentials:'include',
          body: JSON.stringify({ cart_id }),
  
        });
        const data = await response.json();
        if (response.ok) {
          let totalCartPrice = 0;

       
            setCartTotal(data.subtotal)
            

          
          
        } else {
        }
      } catch (error) {
      }
    };
    getCart()
    
    

  },[])
  useEffect(()=>{
    async function GetTaxes() {
      try {
        let latitude, longitude;
    
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
    
            const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10`;
    
            // Fetch data from the API
            fetch(apiUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                if (data && data.address && data.address.country) {
                  setCountryData(data.address.country);
                  setStateData(data.address.state)
    
                } else {
                  throw new Error('Could not retrieve country information.');
                }
              })
              .catch(error => console.error('Error:', error));
          }, (error) => {
            console.error('Error getting location:', error);
          });
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error retrieving country:', error);
        throw new Error('Error retrieving country information.');
      }
    }
    

    
    GetTaxes()
    
 



  },[])
  ;
  useEffect(() => {
    const calculatedTaxRate = TaxRate(stateData, countryData, cartTotal);
    const totalWithTaxes = parseFloat(calculatedTaxRate) + parseFloat(cartTotal);
  
    // Round to two decimal places
    const roundedTotal = Math.round(totalWithTaxes * 100) / 100;
  
    setTaxRate(calculatedTaxRate);
    setFinalTotalAmount(roundedTotal);
  
  }, [stateData, countryData, cartTotal]);
  // Trigger the effect whenever these dependencies change

  // Calculate the total amount including taxes
  
    


  return (
    <div className="mx-auto mt-5" style={{ maxWidth: '900px' }}>
      <MDBRow>
        <MDBCol md="8" className="mb-4">
          <MDBCard className="mb-4">
            <MDBCardHeader
              className="py-3"
              onClick={toggleShippingCollapse}
              style={{ cursor: 'pointer' }}
            >
              <MDBTypography tag="h5" className="mb-0">
                Shipping details
              </MDBTypography>
            </MDBCardHeader>
            <MDBCollapse show={shippingCollapse}>
              <MDBCardBody>
                <form>
                  <div className="mb-3">
                    <label htmlFor="shippingFirstName" className="form-label">
                      First Name
                    </label>
                    <input type="text" className="form-control" id="shippingFirstName" value={shippingInfo.firstName} onChange={(e) => handleInputChange('firstName', e.target.value)} required/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="shippingLastName" className="form-label">
                      Last Name
                    </label>
                    <input type="text" className="form-control" id="shippingLastName" value ={shippingInfo.lastName} onChange={(e)=> handleInputChange('lastName', e.target.value)} required/>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="shippingAddress" className="form-label">
                      Address
                    </label>
                    <GeoapifyContext apiKey={apiKey}>
                    <GeoapifyGeocoderAutocomplete
        apiKey={apiKey}
        placeholder="Enter address here"
        onPlaceSelect={handleShippingPlaceSelect}
      /></GeoapifyContext>
      {selectedShippingPlace && (
        <div>
          <h2>Selected Place:</h2>
          <pre>{JSON.stringify(selectedShippingPlace, null, 2)}</pre>
        </div>
      )}
<div id="autocomplete" className="autocomplete-container"></div>

                  </div>
                  <div className="mb-3">
                    <label htmlFor="shippingEmail" className="form-label">
                      Email
                    </label>
                    <input type="text" className="form-control" id="shippingEmail" value={shippingInfo.email} onChange={(e)=> handleInputChange('email', e.target.value)} required/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="shippingPhone" className="form-label">
                      Phone
                    </label>
                    <input type="text" className="form-control" id="shippingPhone" value={shippingInfo.phone} onChange={(e) => handleInputChange('phone', e.target.value)} required/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="shippingAdditionalInfo" className="form-label">
                    Additional Information<span className="optional-text" >(Optional)</span>
                    </label>
                    <textarea className="form-control" id="shippingAdditionalInfo" rows={4} value={shippingInfo.additionalInfo} onChange={(e)=> handleInputChange('additionalInfo', e.target.value)}></textarea>
                  </div>
                 
                </form>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>

          <MDBCard className="mb-4">
            <MDBCardHeader
              className="py-3"
              onClick={toggleBillingCollapse}
              style={{ cursor: 'pointer' }}
            >
              <MDBTypography tag="h5" className="mb-0">
                Billing details
              </MDBTypography>
            </MDBCardHeader>
            <MDBCollapse show={billingCollapse}>
              <MDBCardBody>
                <form>
                  <div className="mb-3">
                    <label htmlFor="billingFirstName" className="form-label">
                      First Name
                    </label>
                    <input type="text" className="form-control" id="billingFirstName" value={billingInfo.billingFirstName} onChange={(e)=> handleBillingInputChange('billingFirstName', e.target.value)} required/>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="billingLastName" className="form-label">
                      Last Name
                    </label>
                    <input type="text" className="form-control" id="billingLastName" value={billingInfo.billingLastName} onChange={(e)=> handleBillingInputChange('billingLastName', e.target.value)} required/>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="billingAddress" className="form-label">
                      Address
                    </label>
                    <GeoapifyContext apiKey={apiKey}>
                    <GeoapifyGeocoderAutocomplete
        apiKey={apiKey}
        placeholder="Enter address here"
        value={billingValue.billingAddress}
        onChange={(e)=> handleBillingInputChange('billingAddress', e.target.value)}
        onPlaceSelect={handleBillingPlaceSelect}
      /></GeoapifyContext>
      {selectedBillingPlace && (
        <div>
          <h2>Selected Place:</h2>
          <pre>{JSON.stringify(selectedBillingPlace, null, 2)}</pre>
        </div>
      )}
<div id="autocomplete" className="autocomplete-container"></div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="billingAdditionalInfo" className="form-label">
                      Additional Information<span className="optional-text">(Optional)</span>
                    </label>
                    <textarea className="form-control" id="billingAdditionalInfo" rows={4}></textarea>
                  </div>

                </form>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>
        </MDBCol>

        <MDBCol md="4" className="mb-6">
          <MDBCard className="mb-4">
            <MDBCardHeader className="py-3">
              <MDBTypography tag="h5" className="mb-0">Summary</MDBTypography>
            </MDBCardHeader>
            <MDBCardBody>
            <MDBListGroup flush="true">
  {/* Add summary items here */}
  <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
    Products
    <span>{cartTotal}</span>
  </MDBListGroupItem>
  <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
    Shipping
    <span>Free</span>
  </MDBListGroupItem>
  <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 pb-0">
    Taxes
    <span>{taxRate}</span>
  </MDBListGroupItem>
  <MDBListGroupItem className="d-flex justify-content-between align-items-center border-0 px-0 mb-3">
    <div>
      <strong>Total amount</strong>
    </div>
    <span><strong className="total_amount">${finalTotalAmount}</strong></span>
  </MDBListGroupItem>
</MDBListGroup>



              <a onClick={handleShow} >
              <Button variant="primary"  style={{ display: 'block', width: '100%' }}className="submitAddressButton">
              
                Make Purchase
                
              </Button>
              </a>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <Modal show={showPaymentModal} onHide={handleCloseModal} className="paymentModal">
      <Modal.Header>
        <Modal.Title>Secure Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body><CheckoutForm myFunction={handleSubmit} amountTotal={finalTotalAmount}/></Modal.Body>
    </Modal>
    </div>
  );
};

export default AddressForm;

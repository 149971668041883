import React from 'react'
import './Blog.css'
import BlogPost from './BlogPost';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
const Blog = () => {
    // const blogList = [
    //     {
    //       id: 1,
    //       title: "Main Blog Title",
    //       image: "https://via.placeholder.com/500x300",
    //       date: "May 1, 2023",
    //       excerpt:
    //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    //       isMain: true,
    //     },
    //     {
    //       id: 2,
    //       title: "Blog Title 1",
    //       image: "https://via.placeholder.com/150x150",
    //       date: "April 29, 2023",
    //       excerpt:
    //         "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    //       isMain: false,
    //     },
    //     {
    //       id: 3,
    //       title: "Blog Title 2",
    //       image: "https://via.placeholder.com/150x150",
    //       date: "April 27, 2023",
    //       excerpt:
    //         "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //       isMain: false,
    //     },
    //     {
    //       id: 4,
    //       title: "Blog Title 3",
    //       image: "https://via.placeholder.com/150x150",
    //       date: "April 25, 2023",
    //       excerpt:
    //         "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    //       isMain: false,
    //     },
    //   ];
    const [blogs, setBlogs] = useState([])
    useEffect(()=>{
        getBlogs()
    },[])
    
    let getBlogs = async () => {
        let response = await fetch('https://financebackend.darien-c.com/blogs/',{
        method:'GET'

        })//makes call to api

        let data = await response.json()
        
        if(response.status === 200){
            setBlogs(data)

    }}
    
      
  return (
    <div className="blog-container">

        
        {blogs.map((blog) => (
          <Link to={`/blogPost/${blog.id}`} className="custom-link">
          <div className="other-blog" key={blog.id}>
            
            <div className="other-blog-details">
              <h4>{blog.title}</h4>
              <h7>{blog.slug}</h7>
            </div>
          </div>
          </Link>
        ))}
      </div>
  )
}

export default Blog
import React from 'react';
import { useLocation } from 'react-router-dom';
import './ConfirmationPage.css'
const ConfirmationPage = ({  }) => {
    const location = useLocation();
    const { order } = location.state || {}; // Extract order from location.state
   
  if (!order) {
    return null; // Handle if no order data is provided
  }
  const parsedProducts = JSON.parse(order.order_values.products_info);

  return (
    <div className="order-confirmation">
      <h2>Order Confirmation</h2>
      <p>Thank you for your order!</p>
      <p>Order ID: {order.order_values.id}</p>

      {/* Display order details */}
      <div className="order-details">
        {/* Display shipping information */}
        <div className="shipping-info">
          <h3>Shipping Information</h3>
          <p>Name: {`${order.order_values.shipping_first_name} ${order.order_values.shipping_last_name}`}</p>
          <p>Address: {order.order_values.shipping_full_address}</p>
        </div>

        {/* Display billing information */}
        <div className="billing-info">
          <h3>Billing Information</h3>
          <p>Name: {`${order.order_values.billing_first_name} ${order.order_values.billing_last_name}`}</p>
          <p>Address: {order.order_values.billing_full_address}</p>
        </div>
      </div>

      {/* Display ordered products */}
      <br/>
      <br/>
      
      <div className="ordered-products">
        <br/>
        <h3>Ordered Products</h3>
        <ul>
          {parsedProducts.map((item, index) => (
  <li key={index} className="product-item-checkout">
  <span className="product-name">{item.product.name}</span>
  <span className="product-quantity">Quantity: <b>{item.quantity}</b></span>
</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ConfirmationPage;

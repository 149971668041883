import React from 'react'
import ShippingAddressForm from '../components/ShippingAddressForm'
import './ShippingForm.css'
const ShippingForm = () => {
  return (
    <div className="shippingForm">
        <ShippingAddressForm/>
    </div>
  )
}

export default ShippingForm
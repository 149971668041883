const TaxRate = (userState, country, amount) => {
    const usTaxRates = {
        Alabama: 0.04,
        Alaska: 0.0,
        Arizona: 0.056,
        Arkansas: 0.065,
        California: 0.075,
        Colorado: 0.029,
        Connecticut: 0.0635,
        Delaware: 0.0,
        Florida: 0.06,
        Georgia: 0.04,
        Hawaii: 0.04,
        Idaho: 0.06,
        Illinois: 0.0625,
        Indiana: 0.07,
        Iowa: 0.06,
        Kansas: 0.065,
        Kentucky: 0.06,
        Louisiana: 0.0445,
        Maine: 0.055,
        Maryland: 0.06,
        Massachusetts: 0.0625,
        Michigan: 0.06,
        Minnesota: 0.06875,
        Mississippi: 0.07,
        Missouri: 0.04225,
        Montana: 0.0,
        Nebraska: 0.055,
        Nevada: 0.0685,
        NewHampshire: 0.0,
        NewJersey: 0.06625,
        NewMexico: 0.05125,
        NewYork: 0.04,
        NorthCarolina: 0.0475,
        NorthDakota: 0.05,
        Ohio: 0.0575,
        Oklahoma: 0.045,
        Oregon: 0.0,
        Pennsylvania: 0.06,
        RhodeIsland: 0.07,
        SouthCarolina: 0.06,
        SouthDakota: 0.045,
        Tennessee: 0.07,
        Texas: 0.0625,
        Utah: 0.047,
        Vermont: 0.06,
        Virginia: 0.043,
        Washington: 0.065,
        WestVirginia: 0.06,
        Wisconsin: 0.05,
        Wyoming: 0.04
      };
    
      const cadTaxRates = {
        Alberta: 0.05,
        BritishColumbia: 0.07,
        Manitoba: 0.08,
        NewBrunswick: 0.15,
        NewfoundlandAndLabrador: 0.15,
        NovaScotia: 0.15,
        Ontario: 0.13,
        PrinceEdwardIsland: 0.15,
        Quebec: 0.09975,
        Saskatchewan: 0.06,
      };
  
    const calculateTaxRate = (state, country) => {
      const formattedState = state.trim();
  
      if (country === 'United States' && usTaxRates.hasOwnProperty(formattedState)) {
        return parseFloat(usTaxRates[formattedState].toFixed(2));
      } else if (country === 'Canada' && cadTaxRates.hasOwnProperty(formattedState)) {
        return parseFloat((cadTaxRates[formattedState] + 0.05).toFixed(2));
      } else {
        return parseFloat(0.0.toFixed(2));
      }
    };
  
    const taxRate = calculateTaxRate(userState, country);
    const taxAmount = (amount * taxRate).toFixed(2);
  
    return taxAmount;
  };
  
  export default TaxRate;
  
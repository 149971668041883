import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    
      <div className="footer">
        <p>&copy; 2023 FFS. All rights reserved.</p>
        <p>Toronto, Ontario</p>
        <p>Email: info@ffs.com | Phone: (555) 555-5555</p>
      </div>
    
  )
}

export default Footer
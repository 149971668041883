import React from 'react'
import './About.css';
const About = () => {
  return (
    <div className="about-container">
      <h3>Welcome to our website, dedicated to exposing financial frauds and the deceptive mechanisms used by individuals running these companies to exploit naive investors.</h3>
      <p>We believe that everyone has the right to invest their hard-earned money with confidence, but unfortunately, there are those who take advantage of investors' naivety and lack of financial knowledge for their own gain. Our mission is to <b>educate investors and empower them to make informed decisions</b> when investing.</p>
      <p>We aim to uncover the underlying mechanisms that financial fraudsters use to deceive investors. These mechanisms can range from false promises of high returns to complex investment structures that are impossible to understand. We will break down these mechanisms and explain them in simple terms so that investors can easily recognize them.</p>
      <p>We will also share real-life examples of financial frauds and the devastating impact they have on investors. By understanding these examples, investors can learn to avoid similar scams and protect their investments.</p>
      <p>At our website, you will find a wealth of information and resources to help you <b>stay informed and make sound investment decisions</b>. We also welcome your questions and feedback, as we believe that collaboration is key to combatting financial fraud.</p>
      <p>Remember, <b>knowledge is power</b>, and the more you know about the tactics of financial fraudsters, the better equipped you will be to protect yourself and your investments. Thank you for visiting our website, and we hope that you find the information here useful.</p>
    </div>
  )
}

export default About
import axios from "axios";

export const API_URL ='https://financebackend.darien-c.com/'

export const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-type": "application/json"

    }
});

 export default class ApiService{
     static saveStripeInfo(data={}, amount){
         return api.post(`${API_URL}/save-stripe-info/`, data)
     }
 }
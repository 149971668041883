import React, { useEffect, useState } from 'react';
import './Products.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const Products = () => {
    //product list logic
    const [productsList, setProductsList] = useState([])
    
  const [showModal, setShowModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productImg, setProductImg] = useState('');
  const [productID, setProductID] = useState('')

 

  const handleCloseModal = () => {
    setShowModal(false);
  };
    useEffect(()=>{
        getProducts()
    },[])
    
    let getProducts = async () => {
        let response = await fetch('https://financebackend.darien-c.com/products/',{
        method:'GET'

        })//makes call to api

        let data = await response.json()
        
        if(response.status === 200){
            setProductsList(data)

    }}
    //Add Item To Cart Logic
  

    const addToCart = async (productId) => {
      try {
        const cart_id = localStorage.getItem('cart_id')
  
    
        const response = await fetch(`https://financebackend.darien-c.com/cart/add/${productId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include', // include the withCredentials option
          body: JSON.stringify({ cart_id }),
          
        });
        let data = await response.json()
    
        if (response.ok) {
          // Handle successful add-to-cart response
          let cart_id = data['id']
          localStorage.setItem('cart_id',cart_id)
          toast.success('Product added to cart successfully');
        } else {
          // Handle error response
        }
      } catch (error) {
      }
    };

    const ProductShow= async(productID)=>{
      const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/)?.[1];
      const product_Id = productID
      const response = await fetch(`https://financebackend.darien-c.com/productInfo/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          credentials: 'include', // include the withCredentials option
          body: JSON.stringify({ product_Id }),
          
          
        });
        let data = await response.json()
        if (response.ok){
          
          setProductName(data['name'])
          
          setProductDescription(data['description'])
          setProductPrice(data['price'])
          setProductImg(data['image'])
          setProductID(data['id'])
          setShowModal(true)
        }
        else{
        }
    }
    
    

  return (
<div>
<main className="productList">
  <h2>Products</h2>
  <ul>
    {productsList.map((product) => (
      <li key={product.id} className="product-item" >
        
        <div className="product-card" >
          <div className="product-image">
         

            <img src={`https://financebackend.darien-c.com/static${product.image}`} alt={product.name} onClick={() => ProductShow(product.id)} className='productImg'/>
          </div>
          
          <div className="product-details">
            <h3 className="product-name">{product.name} <br/><small className="product-price">${product.price}</small></h3>
          
            <small className="product-price"></small>
            <br/>
            <button className="add-to-cart-button" onClick={() => addToCart(product.id)}>Add To Cart</button>
          </div>
        </div>
      </li>
    ))}
  </ul>
  <div>

  </div>
  <div className='modal-wrapper'>
  <Modal show={showModal} onHide={handleCloseModal} className='productModal'>
    
      <Modal.Header>
        <Modal.Title style={{ width: '100%' }}>{productName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{productDescription}</p>
        <p>Price: {productPrice}</p>

        <img src={`https://financebackend.darien-c.com/static${productImg}`} alt={productName} className='modalImg' />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={() => addToCart(productID)}>
          Add to Cart
        </Button>
      </Modal.Footer>
  </Modal>
</div>


</main>
</div>

  );
};

export default Products;

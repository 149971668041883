const SubmitRegistration = async ({ registerData }) => {
    const url = "https://financebackend.darien-c.com/register/";
    const submitData = {
        email: registerData.email,
        username: registerData.username,
        password: registerData.password2
    }
    console.log(submitData)

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "registerData": submitData }),
        });

        if (response.ok) {
            const data = await response.json();
            return data; // or process the data as needed
        } else {
            console.error('Request failed with status:', response.status);
            throw new Error('Failed to submit registration'); // or include more specific information
        }
    } catch (error) {
        console.error('Error during fetch:', error);
        throw new Error('Failed to submit registration'); // or include more specific information
    }
};

export default SubmitRegistration;

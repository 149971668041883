import React from 'react'
import { useEffect, useState } from 'react';
import './ViewCart.css';

const ViewCart = () => {
    const [cartItems, setCartItems] = useState([])
    
    useEffect(() => {
      const csrfToken = document.cookie.match(/csrftoken=([\w-]+)/);
      const getCart = async () => {
        const cart_id = localStorage.getItem('cart_id')
        try {
          
          const response = await fetch('https://financebackend.darien-c.com/cart/', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken
      
            },
            credentials:'include',
            body: JSON.stringify({ cart_id }),
    
          });
          const data = await response.json();
          if (response.ok) {
            setCartItems(data.items);
          } else {
          }
        } catch (error) {
          console.error(error);
        }
      };
      getCart()
      }, []);


      const handleDeleteItem = async (productId) => {
        const cart_id = localStorage.getItem('cart_id');
      
        try {
          const response = await fetch(`https://financebackend.darien-c.com/cart/delete/${productId}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              // Omit 'X-CSRFToken' header, as the token should be automatically included by the browser
            },
            credentials: 'include',
            body: JSON.stringify({ cart_id }),
          });
      
          if (response.status === 204) {
            // Handle successful response (status 204)
            window.location.reload();
          } else {
            // Handle error response
          }
        } catch (error) {
        }
      };
      
      
      
      
  return (
    <div className="view-cart-container">
      <h1>View Cart</h1>
      {cartItems.length > 0 ? (
        <ul className="cart-items">
        {cartItems.map((item) => (
          <li key={item.product.id} className="cart-item">
            <div className="item-info">
              <img src={`https://financebackend.darien-c.com/static${item.product.image}`} alt={item.product.name} />
              <div className="product-details">
                <h3 className="product-name">{item.product.name}</h3>
                <p className="product-price">${item.product.price}</p>

                <div className="price-quantity">
                  <p className="product-quantity">Qty: {item.quantity}</p>
                </div>
              </div>
            </div>
            <button
              className="remove-button"
              onClick={() => handleDeleteItem(item.product.id)}
            >
              Remove
            </button>
          </li>
        ))}
        <a href='shippingAddress' className="checkout-button-link">
        <button className="checkout-button">Checkout</button>
        </a>
          

      </ul>
      
      ) : (
        <p>Your cart is empty</p>
      )}
    </div>
  );
};

export default ViewCart
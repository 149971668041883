import React, { useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import { toast } from 'react-toastify';
import './RegisterModal.css'
const RegisterModal = ({showCounter}) => {
    const [registerData, setRegisterData] = useState({
        email:"",
        password1:"",
        password2:""
    })
    const [showModal, setShowModal] = useState(true)
    const handleRegisterData = (e) => {
        setRegisterData({
            ...registerData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async ()=>{
        if(registerData.password1 != registerData.password2){
            toast.error("Passwords do not match!")
        }
        else{

        
        const url = "https://financebackend.darien-c.com/register/"
        try{
        const response = await fetch(url,{
            method:"POST",
            headers:{
                "Content-Type": "application/json"
            },
            body:
                {"registerData": registerData}
            
        })
        // Check if the request was successful
        if (response.ok) {
            const data = await response.json();  // Assuming the response is in JSON format
            // Handle the data as needed
        } else {
            // Handle the case where the server returns an error
            console.error('Request failed with status:', response.status);
        }
    } catch (error) {
        // Handle any other errors that may occur during the fetch
        console.error('Error during fetch:', error);
    }}
    }
    // useEffect(()=>{
    //     if(showCounter !== 0){
    //         setShow(true)
    //     }
    // },[showCounter])

    return (
        <div>
            <Modal show={showModal} onHide={setShowModal(false)}>
            <Modal.Header>
                <Modal.Title>
                    Register Account
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter Email Address</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" name="email" onChange={handleRegisterData} value={registerData.email}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Enter Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Password" name="password1"onChange={handleRegisterData} value={registerData.password1}/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" name="password2"onChange={handleRegisterData} value={registerData.password2}/>
                    </Form.Group>
                    <Button variant="primary-light" onClick={(()=>handleSubmit)}>Submit Registration</Button>


                 </Form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default RegisterModal

// BlogPost.js

import React from 'react';
import './BlogPost.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
const BlogPost = ({  }) => {
  const [blogTitle, setBlogTitle] = useState('')
  const [blogSlug, setBlogSlug] = useState('')
  const [blogContent, setBlogContent] = useState('')
  const { id } = useParams(); // Get the ID from the URL
  useEffect(()=>{
    const fetchBlogPost = async (id) => {
      try {
        const response = await fetch('https://financebackend.darien-c.com/getBlog/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: id }) // Send the ID in the request body
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        setBlogTitle(data.blog.title) // Log the fetched blog post
        setBlogSlug(data.blog.slug)
        setBlogContent(data.blog.text)
  
        // Handle the data or update your component state with the fetched blog post
      } catch (error) {
        console.error('Error fetching blog post:', error);
      }
    };
  
    fetchBlogPost(id);

  }, [])


  return (
    <div className="blog-post">
      
      <h2>{blogTitle}</h2>
      <h5>{blogSlug}</h5>
      <br/>
      <br/>
      
      <br/>
      <p>{blogContent}</p>
    </div>
  );
};

export default BlogPost;

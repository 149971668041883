import React, { useState } from "react";
import './Contact.css'
const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // You can replace this console.log statement with your API call to submit the form data to your server.
    };
  return (
    <div className="mainContainer">
      <br/>
    <div className="form-container">
     
    <h2 className="form-header">Contact Us</h2>
    <form onSubmit={handleSubmit}>
      <div className="input-container">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="input-container">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="input-container">
        <label htmlFor="message">Message</label>
        <textarea
          name="message"
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
      </div>
      <button className="submit-btn" type="submit">Submit</button>
    </form>
  </div>
  </div>
  )
}

export default Contact